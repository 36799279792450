<template>
  <div >
    <b-form-group
        label-cols-sm="4"
        :label="$t('Tags.TagEdit.Handlers.ee_email.Subject')"
        label-for="subject"
        v-b-tooltip.hover
    >
      <b-input-group>
        <b-form-input
            :placeholder="$t('General.Form.NoSet')"
            type="text"
            v-model="fakeData.data.subject"
            name="subject"
            required
            :class="{ 'is-invalid': submitted && $v.fakeData.data.subject.$error }"
        ></b-form-input>
        <div v-if="submitted && $v.fakeData.data.subject.$error" class="invalid-feedback">
          <span v-if="!$v.fakeData.data.subject.required">{{ $t('General.Form.Validate.Required') }}</span>
          <span
              v-if="!$v.fakeData.data.subject.maxLength"
          >{{ $t('General.Form.Validate.Maxlength', {count: $v.fakeData.data.subject.$params.maxLength.max}) }}</span>
        </div>
      </b-input-group>
    </b-form-group>

    <b-form-group
        label-cols-sm="4"
        :label="$t('Tags.TagEdit.Handlers.ee_email.EmailsTo')"
        label-for="emailsTo"
        v-b-tooltip.hover
    >
      <b-input-group>
        <b-form-input
            :placeholder="$t('General.Form.NoSet')"
            type="text"
            v-model="emailsTo"
            name="emailsTo"
            required
            :class="{ 'is-invalid': submitted && $v.emailsTo.$error }"
            disabled="disabled"
        ></b-form-input>
        <div v-if="submitted && $v.emailsTo.$error" class="invalid-feedback">
          <span v-if="!$v.emailsTo.required">{{ $t('General.Form.Validate.Required') }}</span>
          <span
              v-if="!$v.emailsTo.email"
          >{{ $t('General.Form.Validate.Email') }}</span>
        </div>
      </b-input-group>
    </b-form-group>

    <b-form-group
        label-cols-sm="4"
        :label="$t('Tags.TagEdit.Handlers.ee_email.SendPack')"
        label-for="emailsTo"
        v-b-tooltip.hover
    >
      <b-form-checkbox
          v-model="fakeData.data.sendPack"
          name="emailsTo"
      ></b-form-checkbox>
    </b-form-group>


    <b-form-group
        label-cols-sm="4"
        :label="$t('Tags.TagEdit.Handlers.ee_email.Template')"
        label-for="template"
    >
      <div class="text-right">
        <b-button size="sm" variant="primary" class="mb-1" @click="fakeData.data.template = generateTemplate()">
          {{$t('Tags.TagEdit.Handlers.ee_email.ResetTemplate')}}
        </b-button>
      </div>
      <b-form-textarea
          :placeholder="$t('General.Form.NoSet')"
          v-model="fakeData.data.template"
          name="template"
      ></b-form-textarea>
    </b-form-group>

    <b-row align-h="between">
      <b-col cols="auto">
        <b-button
            v-if="handler_id !== null"
            v-b-tooltip.hover.viewport
            class="mr-1"
            variant="outline-danger"
            @click="$emit('onDeleteHandler', handler_id)"
        >
          <i class="ri-delete-bin-line"></i>
          {{$t('General.Base.Delete')}}
        </b-button>
      </b-col>
      <b-col cols="auto">
        <b-button
            variant="warning"
            @click="closeHandler"
            class="mr-2"
        >
          {{$t('General.Base.Close')}}
        </b-button>
        <b-button
            variant="success"
            @click="saveMethod"
        >
          {{$t('General.Base.Save')}}
          <b-spinner v-if="loading" small class="align-middle mr-1" variant="light" role="status"></b-spinner>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {maxLength, required, email} from "vuelidate/lib/validators";
import {cloneDeep} from "lodash";
import { tagsService } from '@/helpers/fakebackend/tags.service';

export default {
  name: "ee_email",
  props: ["handler_id"],
  data: function () {
    return {
      fakeData: {},
      patternHandler: {
        id: null,
        handler: "ee_email",
        data: {
          subject: this.$t('Tags.TagEdit.Handlers.ee_email.Them'),
          emailsTo: '',
          sendPack: true,
          template: ''
        }
      },
      submitted: false,
      loading: false
    }
  },
  validations: {
    fakeData: {
      data: {
        subject: { required, maxLength: maxLength(100)}
      }
    },
    emailsTo: { required, email }
  },
  watch: {
    handler_id: function () {
      this.init()
    }
  },
  computed: {
    emailsTo: {
      get: function() {
        if (this.fakeData.data.emailsTo[0] === undefined) {
          return this.$store.getters['authfack/getUser'].data.email
        }
        return this.fakeData.data.emailsTo[0]
      },
      set: function(val) {
        this.fakeData.data.emailsTo = [val]
      }
    }
  },
  methods: {
    ...tagsService,
    closeHandler: function() {
      this.$emit('onCloseHandler')
    },
    saveMethod: function () {
      this.submitted = true
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }
      this.loading = true

      let data = cloneDeep(this.fakeData)
      data.data = JSON.stringify(this.fakeData.data)
      data.tag_id = this.$store.getters['tag/getTag'].id

      this.saveTagHandler('eemail', data).then((data) => {
        this.loading = false
        this.$store.commit('tag/setHandlerMethod', data)
        this.closeHandler()
      })
    },
    generateTemplate: function() {
      let template = '';
      for (let i in this.$store.getters['tag/getTag'].tagSourceId.tagFieldName) {
        let field_obj = this.$store.getters['tag/getTag'].tagSourceId.tagFieldName[i]
        template += '<p><b>'
            + (field_obj.user_name ? field_obj.user_name : field_obj.field_id) + ':</b> '
            + '{{' + field_obj.field_id + '_value' + '}}'
          + '</p>\n'
      }
      return template;
    },
    init: function () {
      if (this.handler_id === null) {
        this.fakeData = cloneDeep(this.patternHandler)
        this.fakeData.data.template = this.generateTemplate()
        this.emailsTo = this.$store.getters['authfack/getUser'].data.email
      } else {
        this.fakeData = cloneDeep(this.$store.getters['tag/getTag'].tagHandlers[this.handler_id])
      }
    }
  },
  created() {
    this.init()
  }

}
</script>

<style scoped>

</style>